.sc-headline-main {
  text-align: center;
  &__subtitle {
    color: var(--c-bk-3);
    font-family: var(--ff-secondary);
    @include font-size(1rem, 1.75rem);

    a {
      color: inherit;
      text-decoration: none;
    }
  }

  &__title {
    font-weight: 700;
    @include font-size(1.5rem, 3.75rem);
    color: black;

    a {
      color: inherit;
      text-decoration: none;
    }
  }
}
