.single {

  &__all_content {
    position: relative;
    overflow: hidden;

    & .single__content-wrp p {
      font-size: 1.25rem;
      line-height: 1.75;
    }

    & .single__description {
      font-size: 1.25rem;
      line-height: 1.75;
      font-weight: bold;      
    } 

    @include m_query($boo-sm, 'min') {
      padding-left: calc(var(--bs-gutter-x) * 0.5);

      &::before {
        --size: 100%;
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        width: 1px;
        height: var(--size);
        transform: translateY(-50%);
        background: var(--c-bk-5);
      }
    }

    .breadcrumb-page {
      @include m_query($boo-md) {
        .active {
          display: none;
        }
      }
    }

  }

  &__title-wrp {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    margin-right: auto;
    margin-left: auto;

    @include m_query($boo-sm, 'min') {
      max-width: 90%;

    }
  }

  &__title {
    font-weight: 700;
    color: black;
    font-size: var(--fs-8);
    margin-bottom: 1rem;

    &-custom_page {
      border-bottom: 1px SOLID #E0E0E0;
      padding-bottom: 1.5rem;
      margin-bottom: 2rem;

    }
  }

  &__description {
    font-size: 15px;

    // @include m_query($boo-sm, 'min') {
    // padding-left: 4rem;

    // }
  }

  &__img-wrp {
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--c-bk-4);
    margin-bottom: var(--sp-40);

    figcaption {
      margin-top: 1rem;
      text-align: right;
      font-size: 15px;
    }

    .description {
      display: block;
    }

    .asset-credit {
      font-weight: 700;
    }
  }

  &__img {
    width: 100%;
    height: auto;
  }

  /*
    ——— Author
  */

  &__author-wrp {
    display: flex;

    margin-bottom: var(--sp-64);
  }

  &__author-img-wrp {
    margin-right: 1rem;

    &-bg_user {
      background: #F5F5F5;
      border-radius: 50%;

      & .single__author-img {
        border-radius: 0;
        width: 60px;
        height: 60px;
        object-fit: contain;
        scale: 0.7;
      }
    }
  }

  &__author-img {
    border-radius: 50%;
    width: 60px;
    height: 60px;
    object-fit: cover;
  }

  &__author-content-wrp {
  }

  &__author-name {
    font-weight: 700;
    font-size: var(--fs-2);
  }

  &__author-meta {
    ul {
      padding: 0;
      list-style: none;
      display: flex;
      font-size: 12px;

      li + li {
        margin-left: 1rem;
      }

      @include m_query($boo-sm) {
        flex-wrap: wrap;
      }
    }

    li + li {
      position: relative;

      &::before {
        --size: 4px;
        content: '';
        position: absolute;
        top: 50%;
        left: -.5rem;
        width: var(--size);
        height: var(--size);
        transform: translate(-50%, -50%);
        background: black;
        border-radius: 50%;
      }
    }
  }

  &__author-copyright {
  }

  &__author-social-links {
    margin-top: 0.2rem;

    ul {
      padding: 0;
      list-style: none;
      display: flex;
      gap: 12px;
    }

    a {
      --size-link: 1.5rem;
      width: var(--size-link);
      height: var(--size-link);
      display: flex;
      background: var(--c-bk-5);
      border-radius: 50%;
      align-items: center;
      justify-content: center;

      .icon {
        transition: var(--g-trans) filter;
      }

      @include hover {
        background: black;

        .icon {
          filter: invert(1);
        }
      }
    }
  }

  /*
  ——— Content
  */

  &__content-wrp {

    margin-bottom: 2rem;

    @include m_query($boo-sm, 'min') {
      margin-left: 4.8rem;
      margin-right: 4rem;
    }

    & blockquote {
      border-left: 2px solid var(--c-secondary-dark);
      padding: 1rem 2rem;
      padding-right: 0rem;

      & p {
        & br~em {
          font-weight: 600;
          font-size: 13px;
          color: var(--c-secondary);
        }
      }
    }

    &.content__locked_cat {
      @include m_query($boo-sm, 'min') {
        margin-left: 0rem;
        margin-right: 0rem;

        & p,
        h1,
        h2,
        h3,
        h4,
        h5 {
          margin-left: 4.8rem;
          margin-right: 4rem;
        }
      }
    }

    > * {
      font-size: var(--fs-2);
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-top: 1.2em;
      margin-bottom: .8em;
    }

    h2 {
      font-size: var(--fs-7);
      color: var(--c-headline-2);

      & span {
        font-size: var(--fs-7);
        color: var(--c-headline-2);
      }
    }

    h3 {
      font-size: var(--fs-6);
      color: var(--c-headline-3);

      & span {
        font-size: var(--fs-6);
        color: var(--c-headline-3);
      }
    }

    h4 {
      font-size: var(--fs-5);
      color: var(--c-headline-4);

      & span {
        font-size: var(--fs-5);
        color: var(--c-headline-4);
      }
    }

    h5 {
      font-size: var(--fs-4);
      color: var(--c-headline-5);

      & span {
        font-size: var(--fs-4);
        color: var(--c-headline-5);
      }
    }

    h6 {
      font-size: var(--fs-2);
      text-transform: uppercase;
    }

    img {
      width: 100%;
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }

    figure {
      img {
        margin: 0;
      }

      figcaption {
        margin-top: 0.5rem;
        font-size: 14px;
      }
    }

    &.content__locked_cat {
      & blockquote {
        flex-direction: column;
        align-items: end;
        background: #F5F5F5;
        border-left: 5px solid #FF0000;
        padding: 2rem 3rem;

        & p {
          margin: 0;
          margin-bottom: 1rem;
        }

        & .content__cat_p {
          display: flex;
          gap: 2rem;
          align-items: center;

          & p {
            width: 80%;
            margin: 0;
          }

          @include m_query($boo-md) {
            flex-wrap: wrap;

            & p {
              width: 100%;
            }
          }

          & .card-special__icon {
            position: relative;
            top: initial;
            left: initial;
            bottom: initial;

          }
        }

        .card-special__icon {
          left: 45px;
          top: 15px;
        }
      }
    }
  }

  &__video-wrp {
    margin-bottom: 2rem;
  }

  &__video {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__video-description {
    font-size: 14px;
    margin-top: 0.5rem;
  }

  &__gallery-wrp {
    margin-bottom: 2rem;
  }

}

.menu-brackdrop {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  z-index: 995;
  -webkit-transition: all .25s ease;
  transition: all .25s ease;

  &.active {
    visibility: visible;
    opacity: 1;

    & ~ .site-header-bottom {
      border: none;
    }
  }
}

/*
  ——— Social Share
  */

.social-share-link {
  max-width: fit-content;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: sticky;
  top: 2rem;
  margin-bottom: auto !important;
  padding-right: calc(var(--bs-gutter-x) * 0.5);

  @include m_query($boo-sm) {
    max-width: 100%;
    align-items: flex-start;
    margin-bottom: 1.5rem;
  }

  @include m_query($boo-sm, 'min') {
    order: -1;
    // position: absolute;
    // left: 0;
    // top: 0;
  }

  &__title {
    font-size: 17px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
  }

  ul {
    list-style: none;
    padding: 0;
    row-gap: 0.2rem;
    display: flex;
    flex-direction: column;

    @include m_query($boo-sm) {
      flex-direction: row;
      column-gap: .2rem;
    }
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    --size-link: 50px;
    width: var(--size-link);
    height: var(--size-link);
    background: black;
    border-radius: 50%;

    @include hover {
      background: var(--c-accent);
    }

    @include m_query($boo-md) {
      --size-link: 40px;

    }

    .icon {
      filter: invert(1);
      --size: 36px;

      @include m_query($boo-md) {
        --size: 26px;
      }
    }
  }
}

.custom_page {

  &__links {
    padding: 0;
    list-style: none;

    &-wrp {
      width: 80%;

      @include m_query($boo-sm, 'min') {
        order: -1;
      }

      @include m_query($boo-sm, 'max') {
        margin-top: 2rem;
      }
    }
  }

  &__link {
    font-family: Source Serif Pro;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 0.625rem;

    a {
      color: #3CBABD;
      text-decoration: none;

      &:hover{
        opacity: 0.8;
      }
    }

    &-active {
      color: #000 !important;
    }
  }

}


.row-single-content {
  // flex-wrap: nowrap;
}

.col-slider-news {
  display: flex;

  @include m_query($boo-sm) {
    flex-direction: column;
  }
}

.col-custom-page {
  display: flex;

  @include m_query($boo-sm) {
    flex-direction: column;
  }

  @include m_query($boo-xl, 'min') {
    @include col(9);
  }

  @include m_query(100rem, 'min') {
    @include col(9);

  }
}


@keyframes flicker {
  0% {
    background-color: white;
    color: #C62A2A;
  }
  50% {
    background-color: #C62A2A;
    color: white;
  }
  100% {
    background-color: white;
    color: #C62A2A;
  }
}

.last-minute {
  display: flex;
  position: relative;
  z-index: 10;
  justify-content: center;
  max-width: 1300px;
  margin: 12px auto;
  align-items: center;

  @include m_query($boo-lg) {
    flex-direction: column;
    align-items: flex-start;
  }
  @include m_query($boo-sm) {
    margin-top: 20px;
  }

  &__box {
    border: 2px #C62A2A solid;
    background-color: white;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 145px;
    height: 34px;
    margin-right: 20px;
    animation: flicker 2s infinite;
    color: #C62A2A;

    &--text {
      font-size: 1rem;
      margin-top: 2px;
      font-family: var(--ff-secondary);
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  &__news {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;

    a {
      text-decoration: none;
      color: #4d4c4c;

      &:hover {
        text-decoration: underline;
      }
    }

    @include m_query($boo-lg) {
      margin-top: 10px;
    }
    @include m_query($boo-sm) {
      align-items: flex-start;
      a {
        -webkit-line-clamp: 2;
      }
    }
  }
}

.error__pages {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  padding: 2rem 0rem;

  & h1 {
    @include font-size(22px, 30px);
  }

  & img {
    max-height: 600px;
    object-fit: cover;

    @include m_query($boo-sm) {
      max-height: 400px;
    }
  }

  @include m_query($boo-lg) {
    flex-wrap: wrap;
  }
}