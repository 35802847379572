.cta-donate-lg {
  background: var(--c-bk-2);
  color: white;
  padding: var(--sp-64) var(--sp-40);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 2rem;

  &__content {
    max-width: 600px;
    margin: 0 auto;
  }

  &__title {
    font-size: var(--fs-4);
    font-family: var(--ff-primary);
    font-weight: 400;
    margin-bottom: 1.5rem;
  }

  &__btn {
    background: black;
    border-radius: 4px;
    color: white;
    padding: 0.5rem 2rem;
    text-decoration: none;
    font-weight: 700;
    display: inline-flex;
  }
}
