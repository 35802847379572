.card-sm-dark {
  padding: 2rem;
  border: 2px solid var(--c-bk-2);
  display: block;
  text-decoration: none;
  color: inherit;

  @include hover {
    // text-decoration: underline;
    color: black;
    background: white;
  }

  &__subtitle {
    text-transform: uppercase;
    font-family: var(--ff-secondary);
    color: var(--c-bk-3);
    margin-bottom: 0.5rem;

    a {
      color: inherit;
      text-decoration: none;
    }
  }

  &__title {
    font-size: var(--fs-2);
    font-weight: 700;
    line-height: 1.1;

  }
}
