.card-ft {
  background: var(--c-accent-light);
  display: flex;
  flex-wrap: wrap;

  @include m_query($boo-lg) {
    flex-direction: column;
  }

  &__img-wrp {
    display: block;

    @include m_query($boo-lg, 'min') {
      width: 45%;
      //max-width: 400px;
      flex: 0 1 40%;
      position: relative;
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;


    @include m_query($boo-lg) {
      max-height: 200px;
    }

    @include m_query($boo-lg, 'min') {
      position: absolute;
      inset: 0;
    }
  }

  &__content-wrp {
    // flex: 1;
    padding: 1.5rem 2rem;
    @include col();

    @include m_query($boo-sm) {
      padding: 1.2rem;
    }

    >*+* {
      margin-top: .75rem;
      margin-bottom: 0;
    }
  }

  &__subtitle {
    text-transform: uppercase;
    font-family: var(--ff-secondary);
    color: var(--c-bk-3);

    a {
      color: inherit;
      text-decoration: none;
      font-size: var(--fs-2);
    }
  }

  &__title {
    // font-size: var(--fs-8);
    @include font-size(1.5rem, 2.125rem);
    font-weight: 700;
    line-height: 1.1;

    a {
      text-decoration: none;

      @include hover {
        text-decoration: underline;
      }
    }
  }

  &__meta {
    text-transform: capitalize;
    >*+* {
      margin-left: 1rem;
      position: relative;

      &::before {
        --size: 100%;
        content: '';
        position: absolute;
        top: 50%;
        left: -.5rem;
        width: 1px;
        height: var(--size);
        transform: translateY(-50%);
        background: black;
      }
    }
  }

  &__date {
    font-size: 12px;
    font-weight: 700;
  }

  &__description {
    font-size: var(--fs-1);
  }

  /*
	——— Variations
	*/
  &--reverse {
    .card-ft {
      &__img-wrp {
        @include m_query($boo-lg, 'min') {
          order: 1;
        }
      }
    }
  }

  &--compact {
    .card-ft {

      &__title {
        font-size: var(--fs-3);
      }
    }
  }

  &--vl {

    flex-direction: column;

    .card-ft {

      &__img-wrp {

        @include m_query($boo-lg, 'min') {
          width: initial;
          max-width: initial;
          flex: initial;
        }
      }

      &__img {
        position: static;

        @include m_query($boo-lg, 'min') {
          max-height: 250px;
        }
      }

      &__meta,
      &__description {
        display: none;
      }
    }
  }

  &--vl-expand {
    height: 100%;

    .card-ft {
      &__img-wrp {
        flex: 1;
      }

      &__content-wrp {
        flex: 0;
      }

      &__img {
        height: 100%;
        max-height: none;
      }
    }
  }
}
