.wdt-ft {
  padding: 2.5rem;
  background: var(--c-accent-light);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__title {
    text-transform: uppercase;
    font-size: var(--fs-4);
    color: var(--c-accent);
    margin-bottom: 0;
  }

  >*+* {
    margin-top: calc(var(--gap) - 1rem);
    position: relative;

    &::before {
      --size: 100%;
      content: '';
      position: absolute;
      top: calc((var(--gap) - 1.5rem) / 2 * -1);
      left: 0;
      width: var(--size);
      height: 1px;
      background: var(--c-bk-5);
    }
  }
}

.wdg-sidebar {

  @include m_query($boo-sm, 'min') {
    min-width: 336px;
  }

  >*+* {
    margin-top: 2rem;
  }
}

.wdg-ft-compact {
  >*+* {
    margin-top: 1rem;
  }

  &:not(.sp-el-y) .wdt-ft__title {
    padding-bottom: 0.5rem;
    border-bottom: 1px solid var(--c-bk-5);
  }
}
