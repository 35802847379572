.card-hz {
  display: flex;
  flex-wrap: wrap;
  // height: 100%;
  align-items: center;

  &__img-wrp {
    display: block;

    width: 40%;
    height: 210px;
    max-width: 345px;
    flex: 0 1 40%;
    position: relative;
    margin-right: 1rem;
  }

  &__img {
    width: 100%;
    object-fit: cover;

    height: 100%;
    //position: absolute;
    inset: 0;
  }

  &__content-wrp {
    // flex: 1;
    padding-right: 2rem;
    @include col();

    @include m_query($boo-sm) {
      padding-right: 1rem;
    }

    > * + * {
      margin-top: .75rem;
      margin-bottom: 0;

      @include m_query($boo-sm) {
        margin-top: 0.25rem;
      }
    }
  }

  &__subtitle {
    text-transform: uppercase;
    font-family: var(--ff-secondary);
    color: var(--c-bk-3);

    a {
      color: inherit;
      text-decoration: none;
      font-size: var(--fs-2);
    }
  }

  &__title {
    // font-size: var(--fs-8);
    @include font-size(1.2rem, 1.875rem);
    font-weight: 700;
    line-height: 1.1;
    margin-bottom: 0;

    a {
      text-decoration: none;

      @include hover {
        text-decoration: underline;
      }
    }
  }

  &__meta {
    text-transform: capitalize;

    > * + * {
      margin-left: 1rem;
      position: relative;

      &::before {
        --size: 100%;
        content: '';
        position: absolute;
        top: 50%;
        left: -.5rem;
        width: 1px;
        height: var(--size);
        transform: translateY(-50%);
        background: black;
      }
    }
  }

  &__date {
    font-size: 12px;
    font-weight: 700;
  }

  &__description {
    font-size: var(--fs-1);
  }

  /*
  ——— Variations
  */

  &--compact {
    .card-hz {
      &__title {
        font-size: var(--fs-4);
      }

      &__img-wrp {
        max-width: 290px;
        height: 195px;
      }
    }
  }

  &--reverse {
    .card-hz {
      &__content-wrp {
        order: 1;
        padding-left: 1.25rem;
        padding-right: 0;

        @include m_query($boo-sm) {
          padding-left: 1rem;
        }
      }
    }
  }

  &--sm {
    height: 100%;

    .card-hz {
      &__content-wrp {
        order: 1;
        padding: 0;
        // padding-left: 1rem;
      }

      &__title {
        font-size: var(--fs-3);
      }

      &__img-wrp {
        // max-width: 100px;
        max-width: 109px;
        max-height: 100px;
        // height: 100%;
      }

      &__meta {
        display: none;
      }
    }
  }

  &--sm-compact {
    height: auto;

    .card-hz {
      &__img-wrp {
        display: none;
      }

      &__content-wrp {
        padding: 0;
      }
    }
  }
  &--md {
    .card-hz {
      &__content-wrp {
        order: 1;
        padding: 0;
      }

      &__title {
        font-size: var(--fs-4);
      }

      &__img-wrp {
        max-width: 210px;
        max-height: 118px;
      }

      &__meta {
        display: none;
      }
    }
  }

  &--sm-expand {
    height: auto;

    .card-hz {
      &__description {
        order: 12;
        flex: 1 0 100%;
        width: 100%;
        margin-top: 1rem;
      }
    }
  }

}
