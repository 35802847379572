.card-vl {
  &__img-wrp {
    display: block;
    margin-bottom: 1rem;
    img {
      width: 100%;
      object-fit: cover;
    }
  }

  &__img {
    width: 100%;
    // height: 100%;
    object-fit: cover;

    @include m_query($boo-lg) {
      max-height: 200px;
    }

    &--sm {
      max-height: 135px;
    }
  }

  &__content-wrp {
    // margin: 1.5rem 0;

    > * + * {
      margin-top: .75rem;
      margin-bottom: 0;

      @include m_query($boo-sm) {
        margin-top: 0.25rem;
      }
    }
  }

  &__subtitle {
    text-transform: uppercase;
    font-family: var(--ff-secondary);
    color: var(--c-bk-3);

    a {
      color: inherit;
      text-decoration: none;
      font-size: var(--fs-2);
    }
  }

  &__title {
    // font-size: var(--fs-8);
    @include font-size(1.5rem, 2.625rem);
    font-weight: 700;
    line-height: 1.1;
    margin-bottom: 0;

    a {
      text-decoration: none;

      @include hover {
        text-decoration: underline;
      }
    }
  }

  &__meta {
    text-transform: capitalize;

    > * + * {
      margin-left: 1rem;
      position: relative;

      &::before {
        --size: 100%;
        content: '';
        position: absolute;
        top: 50%;
        left: -.5rem;
        width: 1px;
        height: var(--size);
        transform: translateY(-50%);
        background: black;
      }
    }
  }

  &__date {
    font-size: 12px;
    font-weight: 700;
  }

  &__description {
    font-size: var(--fs-1);
  }

  /*
	——— Variations
	*/

  &--video {
    .card-vl {
      &__img-wrp {
        position: relative;

        &::before {
          --size: 75px;
          content: '';
          position: absolute;
          bottom: 2rem;
          left: 2rem;
          width: var(--size);
          height: var(--size);
          background: var(--icon-play);
          background-repeat: no-repeat;
          background-size: contain;
          z-index: 1;
          pointer-events: none;
          filter: drop-shadow(0px 5px 7px rgba(0, 0, 0, 0.25));
        }
      }
    }
  }

  &--compact {
    .card-vl {
      &__content-wrp {
        // padding: 0.8rem 0;
      }

      &__title {
        font-size: var(--fs-7);
        margin-bottom: 0;
      }
    }
  }

  &--md {
    .card-vl {

      &__content-wrp {
        margin: 0.8rem 0 0;
      }

      &__img {
        height: 270px;

        &-lg {
          height: 315px;
          object-fit: cover;
        }
        &-sm {
          height: 220px;
        }
      }

      &__title {
        margin-bottom: 0;
        font-size: var(--fs-4);
      }
    }

    &.card-vl--video {
      .card-vl__img-wrp {
        &::before {
          left: 1rem;
          bottom: 1rem;
          --size: 45px;
        }
      }
    }

    &.card-vl--compact {
      .card-vl__title {
        font-size: var(--fs-3);
      }

      .card-vl__img {
        height: 200px;
      }
    }

    &.card-vl--featured {
      .card-vl__title {
        font-size: var(--fs-8);
      }
    }
  }

}
