.sc-title {
  border-bottom: 1px solid var(--c-bk-4);

  &-dark {
    border-bottom: 1px solid var(--c-bk-7);
  }

  &__text {
    display: inline-block;
    padding: .8rem 2rem;
    background: #F3F3F3;
    line-height: 1;
    font-weight: 700;
    font-size: var(--fs-8);
    margin: 0;
    width: fit-content;

    &-dark {
      display: inline-block;
      padding: .8rem 2rem;
      background: var(--c-bk-7);
      line-height: 1;
      font-weight: 700;
      font-size: var(--fs-8);
      margin: 0;
      width: fit-content;

      & a {
        color: #FFF;
        text-decoration: none;
      }
    }

    @media (max-width: $boo-sm) {
      font-size: var(--fs-2);
    }
  }

  &__icon {
    max-height: 35px;
    width: auto;

    @media (max-width: $boo-sm) {
      max-height: 25px;
    }

  }

  a {
    text-decoration: none;
  }
}


.sc-title-gato {
  border-bottom: 4px solid black;
  border-top: none;

  .sc-title__text {
    background: black;
    padding-bottom: 0.5rem;

  }
}
