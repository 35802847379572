.banner-media-audio {
  background: black;
  padding: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: 250px;

  &__headline {
    display: block;
    margin: 0 auto;
    color: white;
    font-family: var(--ff-primary);
    text-transform: uppercase;
    padding: 0.2rem 1rem;
    border-bottom: 1px solid var(--c-accent);
    border-top: 1px solid var(--c-accent);
    font-size: var(--fs-2);
    font-weight: 700;
  }

  &__content-wrp {
    display: flex;
    align-items: center;
    margin-top: 1rem;
  }

  &__img {
    width: 100%;
    flex: 1 1 50%;
    max-width: 50%;
    height: auto;
    margin-bottom: auto;
  }

  &__content {
    padding-left: 1rem;
  }

  &__title {
    font-size: var(--fs-6);
    color: white;
    text-transform: uppercase;
  }

  &__link {
    text-transform: uppercase;
    color: white;
    text-decoration: none;
    font-size: var(--fs-2);
  }
}
