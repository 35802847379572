.news-sl-simple{
  background: black;
  color: white;
  padding: 2rem;
  overflow: hidden;
    position: relative;

  a{
    color: inherit;
  }

  // Para el slider negro de las ninias cuentan
  .swiper {
    //verficar si no jode en otro lado
    position: initial !important;

    &-slide, &-slide a {
      height: auto !important;
      min-height: 100%;
    }

    &-button-prev {
      left: 20px;
    }

    &-button-next {
      right: 20px;
    }
  }

  .swiper-button-prev, .swiper-button-next{
    background: white;

    @include hover{
        background: var(--c-accent-1);
    }

      &::before{
        --size: 1.1rem;
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: var(--size);
        height: var(--size);
        transform: translate(-50%, -50%);
        background-image: url("data:image/svg+xml,%3Csvg width='10' height='18' viewBox='0 0 10 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M-3.93402e-07 9L10 -9.47246e-07L10 18L-3.93402e-07 9Z' fill='%23060606'/%3E%3C/svg%3E%0A");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
  }

  .swiper-button-next{
    transform: scaleX(-1);
  }
}
