@use 'sass:math';

@mixin m_query($width, $type: 'max') {
  $width_value: trim_unit($width);
  $width_unit: unit($width);
  $width_pixels: 0;
  $width_bp: 0;
  $rem_value: 16;

  //Detect unit
  @if $width_unit=='rem'or $width_unit=='em' {
      $width_pixels: $width_value * $rem_value;
  }

  @else {
      $width_pixels: $width_value;
  }

  @if $type=='max' {

      $width_bp: math.div($width_pixels, $rem_value) * 1em;

      @media screen and (max-width: $width_bp) {
          @content;
      }

  }

  @else {

      //@see https://www.w3.org/TR/mediaqueries-4/#mq-min-max
      $width_bp: math.div($width_pixels + .02, $rem_value) * 1em;

      @media screen and (min-width: $width_bp) {
          @content;
      }
  }

}

@mixin visually-hidden {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

@mixin col($col-num: "") {
  @if ($col-num==12) {
      flex: 0 0 100%;
      max-width: 100%;
  }

  @else if ($col-num=="") {
      flex-basis: 0;
      flex-grow: 1;
      min-width: 0;
      max-width: 100%;
  }

  @else if($col-num=="auto") {
      flex: 0 0 auto;
      width: auto;
      max-width: 100%;
  }

  @else {
      flex: 0 0 8.33% * $col-num;
      max-width: 8.33% * $col-num;
  }
}

@mixin select_style {
  background: white;
  border: 1px solid var(--c-primary);
  box-sizing: border-box;
  border-radius: 2px;
  padding: 0.5rem 1rem;
  appearance: none;
  position: relative;
  background-image: var(--icon-arrow-down);
  width: 100%;
  background-repeat: no-repeat;
  background-position: center right 16px;
}

@mixin hover {

  &:hover,
  &:focus {
      @content;
  }
}
