.button {
    &__mailchimp {
        &-yellow {
            padding: 0;
            width: fit-content;
            height: auto;
            max-width: 400px;
            max-height: 50px;

            & > img {
                width: 100%;
                max-width: 400px;
                max-height: 50px;
            }
            // background: var(--c-yellow);
            // display: flex;
            // justify-content: center;
            // padding: 0.5rem 3.75rem;
            border: none;
            border-radius: 6px;
        }
    }
}