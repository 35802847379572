.site-footer {
  background: black;
  padding: var(--sp-40) 0;
  color: white;
  // margin-top: var(--sp-80);

  .col-branding {

    @include col(3);

    @include m_query($boo-sm) {
      @include col(12);
    }

  }

  .col-menus {
  }

  .col-info {
    @include col(3);

    @include m_query($boo-xl) {
      @include col(6);
      margin-top: 2rem;
    }

    @include m_query($boo-sm) {
      @include col(12);
    }
  }

  .col-menu-bottom {
    @include m_query($boo-xl) {
      @include col(6);
    }

    @include m_query($boo-sm) {
      @include col(12);
    }
  }

  &__wrp {
  }

  &__branding {
    padding-right: 2rem;
  }

  &__logo {
    width: 100%;
    max-width: 200px;

    @include m_query($boo-sm) {
      max-width: 100px;
      margin-bottom: 1rem !important;
    }
  }

  &__wrp-menus {
    @include m_query($boo-md, 'min') {
      padding-right: var(--sp-100);
    }
  }

  &__wrp-title {
    margin-bottom: 1.4rem;
    padding-bottom: 1.2rem;
    border-bottom: 1px solid var(--c-bk-1);
    @include m_query($boo-sm) {
      margin-bottom: .8rem;
      padding-bottom: .6rem;
    }
  }

  &__title {
    font-size: var(--fs-5);
    text-transform: uppercase;

    &--small {
    }
  }

  &__suscribed {
    font-size: var(--fs-2);
    color: var(--c-accent-dark);
    font-weight: 600;
    text-decoration: none;
    font-family: var(--ff-secondary);
  }

  &__menus {
    display: flex;
    justify-content: space-between;

    @include m_query($boo-sm) {
      display: block;
      columns: 2;
    }
  }

  &__menu-item {
    ul {
      padding: 0;
      list-style: none;
      font-size: var(--fs-2);

      > * + * {
        margin-top: 0.8rem;

        @include m_query($boo-sm) {
          margin-top: 0;
        }
      }

      a {
        font-weight: 600;
        color: white;
        text-decoration: none;
        font-family: var(--ff-secondary);


        @include hover {
          color: var(--c-accent);
        }
      }
    }
  }

  &__menu-item-hz {
    padding: 1.2rem 0;
    border-top: 1px solid;
    border-bottom: 1px solid;
    border-color: var(--c-bk-1);
    margin-top: 2rem;

    ul {
      padding: 0;
      list-style: none;
      font-size: var(--fs-2);
      display: flex;
      justify-content: center;

      @include m_query($boo-xl) {
        flex-direction: column;
      }

      > * + * {
        margin-left: 2rem;

        @include m_query($boo-xl) {
          margin-left: 0;
        }
      }

      a {
        font-weight: 600;
        color: white;
        text-decoration: none;
        font-family: var(--ff-secondary);

        @include hover {
          color: var(--c-accent);
        }
      }
    }
  }

  &__info {
  }

  &__social {

    margin-bottom: 1.4rem;
    padding-bottom: 1.2rem;
    border-bottom: 1px solid var(--c-bk-1);

    ul {
      padding: 0;
      list-style: none;
      display: flex;
      margin: 0;
      align-items: center;
      flex-wrap: wrap;
      column-gap: 1.25rem;

      > * + * {
        // margin-left: .5rem;
      }

      //li:nth-last-child(1), li:nth-last-child(2) {
      //  display: none;
      //}

      a {
        @include hover {
          .icon {
            filter: var(--c-ft-accent);
          }
        }

        img {
          -webkit-filter: invert(1);
          filter: invert(1);
          height: 1rem;
          max-width: initial;
        }
      }
    }

    .icon {
      filter: invert(1);
      --size: 30px;
    }
  }

  &__copyright {
    display: flex;
    font-family: var(--ff-secondary);
    justify-content: center;
    margin-top: 2rem;
  }
}
