.news-sl-ft {
  &__main-wrp {
  }

  &__img-wrp {
    display: block;
  }

  &__img {
    width: 100%;
    height: 400px;
    object-fit: cover;

    @include m_query($boo-lg) {
      max-height: 200px;
    }

  }

  &__content-wrp {
  }

  &__meta {
    > * + * {
      margin-left: 1rem;
      position: relative;

      &::before {
        --size: 100%;
        content: '';
        position: absolute;
        top: 50%;
        left: -.5rem;
        width: 1px;
        height: var(--size);
        transform: translateY(-50%);
        background: black;
      }
    }
  }

  &__date {
  }

  &__thumbs {
    position: relative;
    padding: 15px 20px;
    background: black;

    .swiper-button-prev {
      left: 10px;
      right: auto;
    }

    .swiper-button-next {
      right: 10px;
      left: auto;
    }

    .swiper-button-prev,
    .swiper-button-next {
      background: var(--c-accent);
      width: 32px;

      @include hover {
        background: var(--c-accent-1);
      }

      &::before {
        --size: 1.1rem;
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: var(--size);
        height: var(--size);
        transform: translate(-50%, -50%);
        background-image: url("data:image/svg+xml,%3Csvg width='10' height='18' viewBox='0 0 10 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M-3.93402e-07 9L10 -9.47246e-07L10 18L-3.93402e-07 9Z' fill='%23060606'/%3E%3C/svg%3E%0A");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        filter: invert(1);
      }
    }

    .swiper-button-next {
      transform: scaleX(-1);
    }
  }

  &__thumb-img {
    // width: 100%;
    filter: brightness(.7);
    transition: var(--g-trans) filter;
  }

  .swiper-slide-thumb-active {
    img {
      filter: brightness(1);
    }
  }
}