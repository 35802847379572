.card-ft-video {
  &__img-wrp, iframe {
    display: block;
    height: 220px;
    position: relative;
    width: 100%;
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
  }

  &__content-wrp {
    background: var(--c-accent);
    color: white;
    padding: 0.2rem 1.25rem;
  }

  &__title {
    font-size: 1rem;
    text-transform: uppercase;
    display: flex;
    align-items: center;

    .icon {
      filter: invert(1);
      margin-right: 0.5rem;
      --size: 2rem;
    }

    .word {
      padding: 0.2rem 0;
    }
  }


}
