// Minimum screen size for Interpolete: 320px;
// Maximum screen size for Interpolete: 1440px;

$max-width-container: 100rem;
$p-container: 4rem;

/*  |> BREAKPOINTS BOOSTRAP
——————————————————————————————————————————————————————*/

$boo-sm: 36em; // 576px
$boo-md: 48em; //768px
$boo-lg: 62em; //992px
$boo-xl: 75em; //1200px
$boo-2xl: 100rem; //1600px

$tw-3xl: 100rem; // 1600px
$line-sizes: 1, 2, 3, 4, 5, 6, 7, 8;

$enable-button-pointers: true !default;

$kbd-padding-y: .5rem;
$kbd-padding-x: .5rem;
$kbd-color: grey;
$kbd-bg: white;


:root {
  @include rfs('--p-container', 1.5rem, 4rem); //Padding Container
  --max-width-container: #{$max-width-container};
  /*
    ——— Transition duration time
    */
  --g-trans: .3s;

  /*
  ——— Fonts (change)
 */
  --ff-primary: "PT Serif", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --ff-secondary: "Source Serif Pro", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --ff-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --ff-tertiary: 'Anton', system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --body-font-size: 1rem;
  --body-line-height: 1.5;
  --ff-size-18: 1.125rem;

  /*
    ——— Colors
    */

  --c-primary: black;
  // --c-secondary: #3DBABD;

  // opcion 1
  // --c-secondary: #295A56;
  // opcion 2
  --c-secondary: #888;

  --c-accent: #3DBABD;

  --c-primary-dark: black;
  --c-secondary-dark: #3DBABD;
  --c-accent-dark: #3DBABD;

  --c-primary-light: #F3F3F3;
  --c-secondary-light: #F3F8FA;
  --c-accent-light: #F3F8FA;

  --c-body-primary: var(--c-bk-2);
  // --c-body-secondary: #6A6A6A;

  --c-body-bg: white;

  --c-bk-1: #19161A;
  --c-bk-2: #4D4C4C;
  --c-bk-3: #848484;
  --c-bk-4: #C9C9C9;
  --c-bk-5: #E0E0E0;
  --c-bk-6: #E1E1E1;
  --c-bk-7: #585858;
  --c-bk-8: #797979;


  --c-accent-1: #E2AF0A;
  --c-accent-2: #C20D19;
  --c-accent-3: #43A9C3;


  --c-headline-2: #01175E;
  --c-headline-3: #012547;
  --c-headline-4: #007EA7;
  --c-headline-5: #195DA2;
  /*
  ——— Colors filter
  */

  --c-ft-accent: invert(79%) sepia(17%) saturate(1471%) hue-rotate(127deg) brightness(80%) contrast(90%);


  /*
   ——— HR
   */
  --hr-color: grey;
  --hr-opacity: 1;

  /*
   ——— Mark
   */
  --mark-padding: .5rem;
  --mark-bg: yellow;

  /*
   ——— Links
   */
  --link-color: var(--c-primary);
  // --link-decoration: var(--c-primary-dark);
  --link-hover-color: var(--c-secondary);
  // --link-hover-decoration: var(--c-secondary-dark);

  /*
 ——— Scrolling
 */
  scroll-behavior: smooth;

  /*
  ——— Font sizes
  */
  @include rfs("--fs-sm", 0.75rem, 0.75rem); //12px
  @include rfs("--fs-1", .9375rem, .9375rem); //15px
  @include rfs("--fs-2", 1rem, 1.125rem); //18px
  @include rfs("--fs-3", 1.125rem, 1.25rem); //20px
  @include rfs("--fs-4", 1.25rem, 1.375rem); //22px
  @include rfs("--fs-5", 1.375rem, 1.5rem); //24px
  @include rfs("--fs-6", 1.5rem, 1.75rem); //28px
  @include rfs("--fs-7", 1.75rem, 1.875rem); //30px
  @include rfs("--fs-8", 1.5rem, 2.125rem); //34px
  @include rfs("--fs-big", 2.5rem, 3.75rem); //60px

  // Revisar cuando este en el server de dev.
  @include rfs("--gap", 1rem, 2.5rem);



  /*
  ——— Spacing
  */
  @include rfs("--sp-40", 2rem, 2.5rem); //40px
  @include rfs("--sp-64", 2rem, 4rem); //64px
  @include rfs("--sp-80", 2.5rem, 5rem); //80px
  @include rfs("--sp-100", 2.5rem, 6.25rem); //100px

  /*
  ——— Border Radius
  */
  // @include rfs("--br-15", 8px, 15px); //15px


  /*
  ——— Social Networks
  */
  --bg-facebook: #3B5998;
  --gd-facebook: linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), #3B5998;
  --bg-twitter: #00ACEE;
  --gd-twitter: linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), #00ACEE;
  --gd-instagram: radial-gradient(127.25% 127.25% at 0% 118.71%, #FED372 0%, #F86F2F 26.56%, #D43089 52.08%, #9E37B8 75.52%, #5F4BC6 100%);
  --gd-instagram-hover: linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), radial-gradient(127.25% 127.25% at 0% 118.71%, #FED372 0%, #F86F2F 26.56%, #D43089 52.08%, #9E37B8 75.52%, #5F4BC6 100%);
  --bg-youtube: #FF0000;
  --gd-youtube: linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), #FF0000;
  --bg-linkedin: #0A66C2;
  --gd-linkedin: linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), #0A66C2;
  --bg-telegram: #0088CC;
  --gd-telegram: linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), #0088CC;
  --bg-spotify: #1DB954;
  --gd-spotify: linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), #1DB954;
}
