.ad {
  display: flex;
  justify-content: center;
  margin: 0 auto;

  &--header {
    margin-top: 1rem;
    margin-bottom: 1rem !important;
  }

  &--w-728 {
   width: 728px;
   min-width: 728px;
  }

  &--w-336 {
    width: 336px;
    min-width: 336px;
  }
  &--w-320 {
    width: 320px;
    min-width: 320px;
  }
    &--w-250 {
    width: 250px;
    min-width: 250px;
  }


  &--w-160 {
    width: 160px;
    min-width: 160px;
  }

  &--w-970 {
    width: 970px;
    min-width: 970px;
  }

  &--h-90 {
    height: 90px;
  }
  &--h-100 {
    height: 100px;
  }

  &--h-250 {
    height: 250px;
  }

  &--h-280 {
    height: 280px;
  }

  &--h-480 {
    height: 480px;
   }

  &--h-600 {
   height: 600px;
  }
}
