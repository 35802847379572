[class*='trim-text'] {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@each $size in $line-sizes {
  .trim-text-#{$size} {
    -webkit-line-clamp: $size;
  }
}

@each $size in $line-sizes {
  .main h1.trim-text-#{$size},
  .main h2.trim-text-#{$size},
  .main h3.trim-text-#{$size},
  .main h4.trim-text-#{$size},
  .main h5.trim-text-#{$size},
  .main h6.trim-text-#{$size} {
    -webkit-line-clamp: initial;
  }
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  @include visually-hidden;
}

.stretched-link::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}

/*————————————————————————————————————————————————————*\
    ●❱ Utilities for layout
\*————————————————————————————————————————————————————*/

.sp-el-x {
  >*+* {
    position: relative;

    &::before {
      --size: 100%;
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 1px;
      height: var(--size);
      transform: translateY(-50%);
      background: var(--c-bk-5);
    }
  }
}


.sp-el-y {
  >*+* {
    position: relative;
    margin-top: var(--gap) !important;

    &::before {
      --size: 100%;
      content: '';
      position: absolute;
      top: calc((var(--gap) / 2) * -1);
      left: 0;
      width: var(--size);
      height: 1px;
      background: var(--c-bk-5);
    }
  }

  >.row+.row {
    &::before {
      --size: calc(100% - 1.5rem);
      left: .75rem;
    }
  }
}

.d-none {
  display: none !important;
}

.pb-g-20 {
  padding-bottom: 1.25rem !important;
}

.pt-g-20 {
  padding-top: 1.25rem !important;
}

.pb-g-40 {
  padding-bottom: var(--gap) !important;
}

.mb-g-20 {
  margin-bottom: 1.25rem !important;
}

.mt-g-20 {
  margin-top: 1.25rem !important;
}

.mt-g-16 {
  margin-top: 1.25rem !important;
}

.mb-g-40 {
  margin-bottom: var(--gap) !important;
}

// .mb-gap-40 {
//   margin-bottom: calc(var(--gap) / 2) !important;
//   padding-bottom: calc(var(--gap) / 2) !important;

// }

.border-b {
  border-bottom: 1px solid var(--c-bk-5);
}

.border-g-b {
  position: relative;

  &::after {
    --size: calc(100% - var(--gap));
    content: '';
    position: absolute;
    bottom: 0;
    left: calc(var(--gap) / 2);
    width: var(--size);
    height: 1px;
    background: var(--c-bk-5);
  }
}

.border-b-ft {
  position: relative;

  &::after {
    --size: calc(100% - 1.5rem);
    content: '';
    position: absolute;
    bottom: 0;
    left: calc(1.5rem / 2);
    width: var(--size);
    height: 4px;
    background: black;
  }
}

.h-100 {
  height: 100%;
}

.h-50 {
  height: 50% !important;
}

.h-auto {
  height: auto !important;
}

.h-45{
  height: 45%;
}

.w-inherit {
  width: inherit;
}

.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}

.sentence {
  text-transform: capitalize;
}

.rotate-180 {
  transform: rotate(180deg);
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-block {
    display: block !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-block {
    display: block !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-block {
    display: block !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-block {
    display: block !important;
  }
}

@media (min-width: 1300px) {
  .d-bpm-none {
    display: none !important;
  }

  .d-bpm-block {
    display: block !important;
  }
}

.bg-multimedia-new {
  background-color: var(--c-accent-light);
}

.text-center {
  text-align: center;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.text-start {
  text-align: start !important;
}

.px-20 {
  padding: 0rem 3.125rem;
}

@media (max-width: 640px) {
  .p-50 {
    padding: 1.125rem !important;
  }

  .detail__content-video iframe {
    max-height: 400px !important;
  }
}

.p-50 {
  padding: 3.125rem;

}

.justify-between {
  justify-content: space-between;
  align-items: center;
}

.flex__wrapper-container {
  flex-grow: 1 !important;
  flex-basis: 315px !important; // for cards min-with 330 aprox.
}

.icon__img-play {
  position: absolute;
  max-height: 130px;
  top: calc((100% - 130px)/2);
  left: calc((100% - 130px)/2);
  z-index: 2;
}

.w-fit {
  width: fit-content !important;
}

.object-top {
  object-position: top;
}
.object-right {
  object-position: right;
}
.object-bottom {
  object-position: bottom;
}
.object-center {
  object-position: center;
}
.object-left {
  object-position: left;
}
.object-left-top {
  object-position: left top;
}
.object-right-top {
  object-position: right top;
}
.object-right-bottom {
  object-position: right bottom;
}
.object-left-bottom {
  object-position: left bottom;
}

.hidden {
  display: none;
}

.social-media__icon {
  cursor: pointer;

  @include m_query($boo-lg) {
    display: none;
  }
}

.search {
  width: inherit;
  opacity: 0;
  pointer-events: none;
  transition: all 0.5s ease;
  display: none;

  &--show {
    pointer-events: auto;
    opacity: 1;
    display: block;
  }

  &__input {
    background: transparent;
    padding-bottom: 0.20rem;
    color: #000;
    border: 0;
    border-bottom: #000 solid 0.3px;

    &::placeholder {
      color: rgba(0, 0, 0, 0.6);
      font-size: 0.9375em;
    }

    &:focus {
      outline: none;
    }
  }
}

.search--show~.icons {
  .open_icon {
    display: none;
  }

  .close_icon {
    display: block;
  }
}

.my-10 {
  margin: 2.5rem 0rem;
}

.text-2xl {
  font-size: 1.5rem/* 24px */;
  line-height: 2rem/* 32px */;
}

.p-5 {
  padding: 1.25rem/* 20px */;
}
