$breakpoint_menu: 1300px;
$breakpoint_menu_lg: 4500px;


.site-header-top {
  background: white;
  color: var(--c-primary);
  padding: 5px 0;
  border-bottom: 3px solid var(--c-bk-4);

  .row {
    align-items: center;
  }

  .icon {
    // filter: invert(1);
  }

  &__icons {
    display: flex;

    >*+* {
      margin-left: 1rem;
    }
  }

  &__social {
    display: flex;
    align-items: center;

    ul {
      padding: 0;
      list-style: none;
      display: flex;
      margin: 0;
      align-items: center;
      column-gap: 1.25rem;

      //>*+* {
      //  margin-left: .5rem;
      //}
    }

    a {
      display: flex;
      align-items: center;
      justify-self: center;

      @include hover {
        .icon {
          filter: var(--c-ft-accent);
        }
      }

      img{
        height: 1rem;
        max-width: initial;
      }
    }
  }

  &__search {
    position: relative;

    &::before {
      --size: 80%;
      content: '';
      position: absolute;
      top: 50%;
      left: -0.5rem;
      width: 1px;
      height: var(--size);
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      background: black;
    }

    button {
      border: none;
      padding: 0;
      background: none;
      display: flex;
    }

    .icon {
      background-size: 120%;
    }
  }

  &__info {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    >*+* {
      margin-left: 1.5rem;
    }

    @include m_query($boo-sm) {
      display: none;
    }
  }

  &__hour {
    display: flex;
    align-items: center;

    .icon {
      background-size: 120%;
    }
  }

  &__date {
    position: relative;

    &::before {
      --size: 80%;
      content: '';
      position: absolute;
      top: 50%;
      left: -0.75rem;
      width: 1px;
      height: var(--size);
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      background: black;
    }
  }
}

.site-header-body {
  .col-branding {
    @include m_query($boo-lg) {
      order: -1;
    }
  }

  .col-menu {
    @include m_query($breakpoint_menu, 'min') {
      display: none;
    }
  }

  .col-social {


    @include m_query($breakpoint_menu, 'min') {
      display: none;
    }

    @include m_query($boo-lg) {
      display: none;
    }

    >* {
      display: none;
    }

    // @include m_query($boo-lg) {
    //   display: block;
    // }


  }
}

.site-header-bottom {
  border-top: 1px solid var(--c-bk-5);
  border-bottom: 1px solid var(--c-bk-5);
  position: relative;
  z-index: 2000;

  .row {
    clip-path: none;
    z-index: 1;
    position: relative;
  }

  @include m_query($breakpoint_menu) {
    .bottom {
      // display: none;
      padding: 0 !important;
    }
  }
}

.site-header {
  // overflow: hidden;
  position: relative;

  .site-header-bottom {
    .bottom {
      @include m_query($breakpoint_menu, 'min') {
        padding: 0;
      }
    }

    .menu-toggle--open {
      display: none;
    }

    .menu-toggle__close {
      @include m_query($breakpoint_menu, 'min') {
        display: none;
      }
    }
  }

  .top {
    align-items: center;
  }

  .bottom {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  &__branding {
    padding: 0.5rem 0;
    text-align: center;

    a {
      display: block;
      // transform: scale(1.3);
      // -webkit-transform: scale(1.3);

      @include m_query($boo-lg) {
        transform-origin: left center;
        -webkit-transform-origin: left center;
      }
    }

    @include m_query($boo-lg) {
      text-align: left;
    }
  }

  &__logo {
    max-width: 300px;
    width: 100%;
  }

  &__ads {
    text-align: center;
    padding: 1rem 0;

    img {
      margin-left: auto;
      width: 970px;
      height: auto;
    }
  }


  &__menu {
    max-width: 1300px;
    margin: 0 auto;

    @include m_query($breakpoint_menu) {
      display: flex;
      justify-content: flex-end;
    }

    ul {
      padding: 0;
      margin: 0;
      list-style: none;

      @include m_query($breakpoint_menu) {
        text-transform: uppercase;
      }
    }
  }

  &:not(.site-header--compact) {

    .site-header__menu {

      /*
    ——— STYLES MENU FOR DESKTOP
    */
      @include m_query($breakpoint_menu, "min") {
        .menu {
          display: flex;
          position: relative;
          justify-content: center;
          gap: 1.75rem;

          //> li + li {
          //  margin-left: .5rem;
          //}

          >li {
            position: relative;

            /*
          ——— This pseudo-element helps to improve the hover in the main menu
          */
            &::after {
              --size: 100%;
              content: "";
              position: absolute;
              bottom: 0;
              left: 0;
              width: var(--size);
              height: 20px;
              transform: translateY(100%);
              -webkit-transform: translateY(100%); /* Safari */
            }
          }
        }

        .sub-menu {
          position: absolute;
          display: block;
          bottom: 0;
          transform: translate(-1.5rem, calc(100% + 1rem));
          -webkit-transform: translate(-1.5rem, calc(100% + 1rem)); /* Safari */
          opacity: 0;
          left: -99999rem;
          transition: opacity 0.15s linear, left 0.1s 0.15s;
          -webkit-transition: opacity 0.15s linear, left 0.1s 0.15s; /* Safari */
          min-width: 230px;
          padding: 1rem 0.5rem;
          background: #f5f5f5;
          border-radius: 10px;
          border-top: 4px solid var(--c-primary);

          .menu-item-has-children {
            &::before {
              right: 8px;
              transform: translateY(-8px) rotate(-90deg);
              -webkit-transform: translateY(-8px) rotate(-90deg);
            }
          }

          &::before {
            --size: 16px;
            content: "";
            position: absolute;
            top: -9px;
            left: 2.5rem;
            width: var(--size);
            height: var(--size);
            background-size: contain;
            background: linear-gradient(136deg,
                var(--c-primary) 0%,
                var(--c-primary) 47.9%,
                transparent 47.91%);
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);

            border-radius: 6px;
          }

          li+li {
            margin-top: 0.2rem;
          }

          a {
            padding: 0.2rem 1rem;
            display: block;
            font-weight: 400;
            border-radius: 5px;

            @include hover {
              background: #ececec;
              color: var(--c-secondary);
            }
          }

          /*
      ——— Third Level
      */

          .sub-menu {
            transform: translate(calc(100% - 8px), calc(75% - 20px));
            -webkit-transform: translate(calc(100% - 8px), calc(75% - 20px));
            z-index: 10;
            border: none;
            border-left: 4px solid darkgray;

            &::before {
              display: none;
            }

            .sub-menu {
              transform: translate(calc(95% - 1px), calc(75% - 20px));
              -webkit-transform: translate(calc(95% - 1px), calc(75% - 20px));
            }
          }
        }

        .menu-item-has-children {
          position: relative;

          &::before {
            --size: 14px;
            content: "";
            position: absolute;
            top: 54%;
            right: 0;
            width: var(--size);
            height: var(--size);
            transform: translateY(-50%);
            -webkit-transform: translateY(-50%);
            background-image: var(--icon-arrow-down);
            background-size: contain;
            background-position: center;
            pointer-events: none;
          }

          .ancestor-wrapper {
            >a {
              padding-right: 1.6rem;
            }
          }
        }

        li {
          position: relative;

          &:focus,
          &:active,
          &:hover {
            >.sub-menu {
              opacity: 1;
              left: 0;
              transition: opacity 0.15s linear;
              -webkit-transition: opacity 0.15s linear; /* Safari */
            }
          }

          //Show sub-menu in focus
          &.focus {
            >.sub-menu {
              opacity: 1;
              left: 0;
              transition: opacity 0.15s linear;
              -webkit-transition: opacity 0.15s linear; /* Safari */
            }
          }
        }

        a {
          font-weight: 700;
          text-decoration: none;
          font-family: var(--ff-secondary);
          font-size: 14px;
        }
      }

      /*
  —————————————————————————————————————————————————
  */
    }
  }

}

.main-navigation.effect--leftIn {
  display: none;
}

.site-header--compact {
    .main-navigation.effect--leftIn {
      display: block;
    }
}


/*————————————————————————————————————————————————————*\
    ●❱ MENU MOBILE
\*————————————————————————————————————————————————————*/

.menu-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.3rem;
  border: none;
  background: 0 0;
  border-radius: 10px;

  .icon {
    --size: 30px;
  }

  &__close {
    margin-left: auto;
    margin-bottom: 2.5rem;
    z-index: 1;
    transition: opacity 0.15s linear, right 0.1s 0.15s;
    -webkit-transition: opacity 0.15s linear, right 0.1s 0.15s;
    background: none;
    // border: 1px solid black;
    // border-radius: 50px;

    @media only screen and (max-width: 350px) {
      margin-bottom: 0.5rem;
    }

    .icon {
      --size: 22px;
    }
  }

}

.site-header:not(.site-header--compact) {
  .menu-toogle {
    @include m_query($breakpoint_menu, 'min') {
      display: none;
    }
  }
}

.main-navigation {
  @include m_query($breakpoint_menu, 'min') {
    .sub-menu-toggle {
      display: none;
    }
  }

  &-header_content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  & img.main-navigation-logo {
    max-width: 180px;
    margin-right: 10px;
    &~button {
      min-width: 80px;
    }
  }
}

/*
——— STYLES MENU FOR MOBILE
*/

@include m_query($breakpoint_menu) {
  .main-navigation {

    &.effect--leftIn {
      display: block;
      position: fixed;
      left: 0;
      // width: 100%;
      width: 20%;
      min-width: 310px;
      height: 100%;
      background: white;
      top: 0;
      padding: 3rem 5rem;
      padding-left: var(--p-container);
      padding-right: var(--p-container);
    }

    &--compact {
      display: none;
    }
    @include m_query($boo-md) {
      padding: 2.4rem 4rem;
    }

    @include m_query($boo-sm) {
      padding: .8rem 2rem;
    }

    /** Toggles buttons */
    .ancestor-wrapper {
      display: flex;
      align-items: center;

      a {
        flex-basis: 0;
        flex-grow: 1;
        min-width: 0;
        max-width: 100%;
      }

      button {
        --size: 2rem;
        justify-content: center;
        align-items: center;
        display: flex;
        border-radius: 10px;
        height: var(--size);
        width: var(--size);
        border: none;
        background: #f9f9f9;

        .icon {
          --size: 14px;
        }

        &[aria-expanded="true"] {
          background: #efefef;

          .icon {
            transform: rotate(180deg);
          }
        }
      }
    }

    .menu {
      li a {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        text-decoration: none;
      }

      >li a {
        position: relative;

        &::before,
        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 1px;
          background-image: linear-gradient(270deg,
              rgba(243, 243, 243, 0) 0%,
              #f1f1f1 100%);
        }

        &::after {
          top: auto;
          bottom: -1px;
          left: 0;
        }
      }

      a {
        color: #585858;
        font-size: 1.125rem;
        font-weight: bold;
        display: block;
      }
    }

    .sub-menu {
      margin-bottom: 1rem;
      padding-left: 1rem;
      display: none;

      a {
        font-size: 1rem;

        &::before,
        &::after {
          height: 0 !important;
        }
      }

      .sub-menu {
        a {
          font-size: 0.875rem;
          line-height: 1;
          color: #a8a8a8;
        }
      }
    }

    li {}

    /* ->  ANIMATIONS TYPES  */

    &.effect--fadeIn {
      left: -999rem;
      opacity: 0;
      transition: opacity 0.15s linear, left 0.1s 0.15s;
      -webkit-transition: opacity 0.15s linear, left 0.1s 0.15s;

      &.show--fade {
        opacity: 1;
        left: 0;
        transition: opacity 0.15s linear;
        -webkit-transition: opacity 0.15s linear;
      }
    }

    &.effect--rightIn {
      left: auto;
      right: -100%;
      transition: right var(--g-trans);
      -webkit-transition: right var(--g-trans);

      &.show--fade {
        opacity: 1;
        right: 0;
      }
    }

    &.effect--leftIn {
      left: -100%;
      transition: left var(--g-trans);
      -webkit-transition: left var(--g-trans);

      &.show--fade {
        opacity: 1;
        left: 0;
      }
    }
  }
}


.site-header-top__social {
  ul {
    justify-content: flex-end;
  }

  &-search {
    display: none;

    & > form {
      margin-right: 0.5rem;
      width: auto;
      min-width: 300px;
      max-width: 500px;

      & > input {
        width: auto;
        min-width: 300px;
        max-width: 500px;
      }
    }

    @include m_query($boo-lg, 'min') {
      display: flex;
      justify-content: end;
      width: 60%;
      padding-left: 1.25rem;
    }

    @include m_query($boo-xl) {
      display: flex;
    }
  }
}

.menu-toggle--open {
  @include m_query($boo-lg) {
    margin-left: auto;
  }
}


/*————————————————————————————————————————————————————*\
    ●❱ Compact
\*————————————————————————————————————————————————————*/


.site-header--compact {

  @include m_query($breakpoint_menu_lg) {
    .site-header-bottom {
      .bottom {
        padding: 0;
      }

      .menu-toggle--open {
        display: none;
      }
    }
  }

  .menu-toggle__close {
    display: flex !important;
  }

  .col-menu {
    display: block;

    .menu-toggle {
      @include m_query($breakpoint_menu_lg) {
        display: block !important;
      }
    }
  }

  .col-social {
    display: block;

    >* {
      display: block;
    }

    @include m_query($boo-lg) {
      display: none;
    }
  }

  .site-header-top {
    display: none;
  }

  .site-header__menu {
    // max-width: 1300px;
    // margin: 0 auto;

    @include m_query($breakpoint_menu_lg) {
      display: flex;
      justify-content: flex-end;
    }

    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      text-transform: uppercase;
    }
  }

  .main-navigation {
    @include m_query($breakpoint_menu_lg) {
      .sub-menu-toggle {
        display: block !important;
      }
    }
  }

  /*
  ——— STYLES MENU FOR MOBILE
  */

  @include m_query($breakpoint_menu_lg) {
    .main-navigation {

      &.effect--leftIn {
        position: fixed;
        left: 0;
        // width: 100%;
        width: 20%;
        min-width: 310px;
        height: 100%;
        background: white;
        top: 0;
        padding: 3rem 5rem;

        padding-left: var(--p-container);
        padding-right: var(--p-container);
      }

      @include m_query($boo-md) {
        padding: 2.4rem 4rem;
      }

      @include m_query($boo-sm) {
        padding: .8rem 2rem;
      }

      /** Toggles buttons */
      .ancestor-wrapper {
        display: flex;
        align-items: center;

        a {
          flex-basis: 0;
          flex-grow: 1;
          min-width: 0;
          max-width: 100%;
        }

        button {
          --size: 2rem;
          justify-content: center;
          align-items: center;
          display: flex;
          border-radius: 10px;
          height: var(--size);
          width: var(--size);
          border: none;
          background: #f9f9f9;

          .icon {
            --size: 14px;
          }

          &[aria-expanded="true"] {
            background: #efefef;

            .icon {
              transform: rotate(180deg);
              -webkit-transform: rotate(180deg);
            }
          }
        }
      }

      .menu {
        li a {
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
          text-decoration: none;
        }

        >li a {
          position: relative;

          &::before,
          &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background-image: linear-gradient(270deg,
                rgba(243, 243, 243, 0) 0%,
                #f1f1f1 100%);
          }

          &::after {
            top: auto;
            bottom: -1px;
            left: 0;
          }
        }

        a {
          color: #585858;
          font-size: 1.125rem;
          font-weight: bold;
          display: block;
        }
      }

      .sub-menu {
        margin-bottom: 1rem;
        padding-left: 1rem;
        display: none;

        a {
          font-size: 1rem;

          &::before,
          &::after {
            height: 0 !important;
          }
        }

        .sub-menu {
          a {
            font-size: 0.875rem;
            line-height: 1;
            color: #a8a8a8;
          }
        }
      }

      li {}

      /* ->  ANIMATIONS TYPES  */

      &.effect--fadeIn {
        left: -999rem;
        opacity: 0;
        transition: opacity 0.15s linear, left 0.1s 0.15s;
        -webkit-transition: opacity 0.15s linear, left 0.1s 0.15s;

        &.show--fade {
          opacity: 1;
          left: 0;
          transition: opacity 0.15s linear;
          -webkit-transition: opacity 0.15s linear;
        }
      }

      &.effect--rightIn {
        left: auto;
        right: -100%;
        transition: right var(--g-trans);
        -webkit-transition: right var(--g-trans);

        &.show--fade {
          opacity: 1;
          right: 0;
        }
      }

      &.effect--leftIn {
        left: -100%;
        transition: left var(--g-trans);
        -webkit-transition: left var(--g-trans);

        &.show--fade {
          opacity: 1;
          left: 0;
        }
      }
    }
  }


}
