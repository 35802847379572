.tabs {
  background: #F3F3F3;

  ul {
    display: flex;
    padding: 0;
    list-style: none;
    justify-content: space-between;
    max-width: 700px;
  }

  li {}

  a {
    padding: 1rem 1.5rem;
    display: inline-flex;
    text-decoration: none;
    font-size: var(--fs-3);
    position: relative;

    &::before {
      --size: 100%;
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: var(--size);
      height: 4px;
      background-color: var(--c-accent);
      opacity: 0;
      transition: var(--g-trans) opacity;
    }

    @include hover {
      &::before {
        opacity: 1;
      }
    }

    &.active {
      font-weight: 700;
      position: relative;

      &::before {
        opacity: 1;
      }
    }
  }
}
