.card {
  // horizontal multimedia full width card
  &-multi-hz {
    width: 100%;
    @include padding(1.125rem, 3.125rem);
    padding-bottom: 1.25rem;

    &__img {
      &-play {
        position: absolute;
        @include max-height(92px, 130px);
        top: calc((100% - 130px) / 2);
        left: calc((100% - 130px) / 2);
        z-index: 2;

        @media only screen and (max-width:640px) {
          top: calc((100% - 160px) / 2);
          left: calc((100% - 92px) / 2);
        }
      }

      &-wrp {
        width: 100%;
        height: 100%;

        & img {
          object-fit: cover;
          max-height: 497px;
          height: 100%;
          width: 100%;
        }
      }
    }

    &__title {
      font-size: var(--fs-7);
      font-weight: bold;
      color: #000;
      text-align: center;
      margin: auto;
      margin-top: 1.25rem;
      max-width: 80%;

      & a {
        text-decoration: none;
      }
    }

    &__copy {
      font-size: var(--fs-sm);
      font-weight: bold;
      color: var(--c-bk-2);
      text-align: center;
      margin: 0.313rem 0rem;
      max-width: 80%;
      margin: auto;
      display: block;
    }

    &__description {
      font-size: var(--fs-1);
      font-weight: bold;
      color: var(--c-bk-2);
      text-align: center;
      margin: 0.313rem 0rem;
      max-width: 80%;
      margin: auto;
    }
  }

  // vertical multimedia card
  &-multi-vl {
    width: 100%;
    // padding: 3.125rem;
    padding-bottom: 1.25rem;

    & > div {
      position: relative;
    }

    &__img {
      &-play {
        position: absolute;
        max-height: 92px;
        top: calc((100% - 92px) / 2);
        left: calc((100% - 92px) / 2);
        z-index: 2;
      }

      &-wrp {
        width: 100%;
        height: 100%;

        & img {
          object-fit: cover;
          max-height: 200px;
          height: 100%;
          width: 100%;
        }
      }
    }

    &__title {
      font-size: var(--fs-4);
      font-weight: bold;
      color: #000;
      text-align: start;
      margin-top: 1.375rem;
      margin-bottom: 0.563rem;

      & a {
        text-decoration: none;
      }
    }

    &__category {
      font-size: var(--fs-2);
      font-weight: bold;
      color: var(--c-bk-2);
      text-align: center;
      margin: 0.875rem 0rem;
    }

    &__description {
      font-size: var(--fs-1);
      font-weight: 400;
      color: var(--c-bk-2);
      text-align: start;
      margin: 0.563rem 0rem;
    }

    @media (max-width: $boo-lg) {

      &::before {
        background: transparent !important;
      }

    }
  }
}
