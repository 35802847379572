.pagination {
    padding-top: 2.5rem;
    /* 40px */
    padding-bottom: 2.5rem;
    /* 40px */

    &__nav {
        display: flex;
        justify-content: center;
    }

    &__items {
        list-style: none;
        display: flex;
        align-items: center;
        gap: 1rem;

        & a {
            text-decoration: none;
        }
    }

    &__controls a {
        display: block;
        height: 100%;
        background: #F3F3F3;
        color: #FFF;

        &:hover {
            background: #e4e3e3;
        }
    }

    &__item {
        font-family: var(--ff-secondary);
        font-size: 600;
        color: var(--c-bk-3); //
        font-size: 1.125em;
        line-height: 25px;
        padding: 3px 10px;

        &.active {
            background: #000; //

            & a {
                color: #FFF;
            }
        }
    }
}