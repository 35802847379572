.card-gato-simple {
  display: flex;
  flex-direction: row-reverse;
  height: 100%;
  position: relative;


  @include m_query($boo-md) {
    flex-direction: column;
  }

  &.is-manipulated {
    --bg: var(--c-accent-1);
    --text-color-meta: black;
    --icon-is-news: var(--icon-is-manipulated);

    &.card-special--dark {
      .card-special__subtitle {
        color: white;
      }
    }
  }

  &.is-fake {
    --bg: var(--c-accent-2);
    --icon-is-news: var(--icon-is-fake);
  }

  &.is-explainer {
    --bg: var(--c-accent-3);
    --icon-is-news: var(--icon-is-explainer);
  }

  &__img-wrp {
    flex: 1;

    @include m_query($boo-md, 'min') {
      display: flex;
      flex-direction: column;

      a {
        flex: 1;
      }

      img {
        height: 100%;
        object-fit: cover;
      }
    }

    > * + * {
      margin-top: 1rem;
    }

    a {
      position: relative;
      display: block;
    }
  }

  &__img {
    width: 100%;
  }

  &__meta {
    text-transform: capitalize;
    font-size: 12px;
    font-weight: 700;
    text-align: right;

    > * + * {
      margin-left: 1rem;
      position: relative;

      &::before {
        --size: 100%;
        content: '';
        position: absolute;
        top: 50%;
        left: -.5rem;
        width: 1px;
        height: var(--size);
        transform: translateY(-50%);
        background: black;
      }
    }
  }

  &__icon {
    --size: 5rem;
    background-image: var(--icon-is-news, var(--icon-is-fake));
    position: absolute;
    left: 1rem;
    bottom: 1rem;
    z-index: 1;
  }

  &__date {
  }

  &__content-wrp {

    @include m_query($boo-md, 'min') {
      flex: 0 0 35%;
      max-width: 35%;
      padding-right: 2rem;
      align-self: start;
    }

    > * + * {
      margin-top: 15px;
    }
  }

  &__subtitle {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
    padding: 0.3rem 1.5rem;
    background: var(--bg, black);
    color: var(--text-color-meta, white);
    max-width: fit-content;
    transition: var(--g-trans) background;

    a {
      color: inherit;
      text-decoration: none;
    }
  }

  &__title {
    margin-bottom: 1rem;
    font-size: var(--fs-7);
    font-weight: 700;

    a {
      text-decoration: none;

    }
  }

  &__description {
    font-size: 15px;
  }
}
