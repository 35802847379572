/*————————————————————————————————————————————————————*\
    ●❱ BASIC GRID - Inspired in Bootstrap
\*————————————————————————————————————————————————————*/

.container,
.container-fluid {
    width: 100%;
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
    margin-right: auto;
    margin-left: auto;
}

.container {
    max-width: var(--max-width-container, 1440px)
}

.row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));

    /*
    ——— Delete separator lines at screen responsive.
    */
   clip-path: inset(0 2px 0 2px);
}

.row > * {
    box-sizing: border-box;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-top: var(--bs-gutter-y);
}

.g-40 {
  --bs-gutter-y: var(--gap);
  --bs-gutter-x: var(--gap);
}

.g-12 {
  --bs-gutter-y: 0.75rem;
  --bs-gutter-x: 0.75rem;
}

.g-24{
  --bs-gutter-y: 1.5rem;
  --bs-gutter-x: 1.5rem;
}

.col {
  flex: 1 0 0%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

@media (min-width: $boo-sm) {
  .col-sm {
    flex: 1 0 0%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}
@media (min-width: $boo-md) {
  .col-md {
    flex: 1 0 0%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}
@media (min-width: $boo-lg) {
  .col-lg {
    flex: 1 0 0%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}
@media (min-width: $boo-xl) {

  .col-expand {
    flex: 1;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
 }

  .col-fixed {
      flex: 0 0 auto;
      width: 43%;
  }

 .col-fixed-250 {
      flex: 0 0 250px;
      width: 250px;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
  }

 .col-fixed-300 {
      flex: 1;
      width: 300px;
      -webkit-box-flex: 0;
      -ms-flex: 1;
  }

  .col-xl {
    flex: 1 0 0%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}


.d-block {
  display: block !important;
}

.d-flex {
  display: flex !important;
}

.d-none {
  display: none !important;
}

.-order-1 {
  order: -1;
}

@media (min-width: 576px) {
  .d-sm-block {
    display: block !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .order-sm-initial {
    order: initial;
  }
}
@media (min-width: 768px) {
  .d-md-block {
    display: block !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-none {
    display: none !important;
  }
}
@media (min-width: 992px) {
  .d-lg-block {
    display: block !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-block {
    display: block !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
}
@media (min-width: 1400px) {
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
}

.d-1500-none{

  @include m_query(1500px) {
    display: none;
  }

}


.col-slider-news{
  @include col(12);

  @include m_query($boo-lg, 'min') {
      @include col(7);
  }

  @include m_query($boo-xl, 'min') {
    @include col(8);
  }

  @include m_query($boo-2xl, 'min') {
    @include col(9);
  }
}

.list_detail {
  display: grid;
  //gap: 0.875rem;

  grid-template-columns: repeat(3, minmax(0, 1fr));

  @include m_query($boo-lg) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (width: $boo-md) {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }

  @include m_query($boo-md) {
     grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  & > * {
    // copy style of .row test
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-top: var(--bs-gutter-y);
  }

}


.detail__content {
  padding: 3.125rem;
  @include padding(1.125rem, 3.125rem);

  &-title {
    font-weight: 600;
    margin-bottom: 0.5rem;
    color: #000;
    font-size: var(--fs-big);
    text-align: center;
  }

  &-description {
    font-weight: 400;
    padding-bottom: 0.5rem;
    color: var(--c-bk-3);
    font-size: var(--fs-6);
    text-align: center;
  }

  &-photo {
    width: 100%;
    max-height: 500px;
    object-fit: cover;
  }

  &-video{
    width: 100%;
    // max-height: 560px;

    & iframe{
      width: 100%;
      max-height: 560px;

    }
  }
}

.see__more {
  background: var(--c-bk-6);

  .card-multi-vl__img-wrp img {
    max-height: 315px;
    height: 315px;
  }

  .card-multi-vl__title {
    font-size: var(--fs-6);
  }

  & h1, h6 {
    margin: 0.5rem 0rem;
  }
}
