.banner-donate {
  background: var(--c-bk-1);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: 250px;
  @include m_query(640px) {
    padding: 1.5rem;
  }

  &__logo {
    display: block;
    margin: 0 auto;
  }

  &__content-wrp {
    display: flex;
    align-items: center;
    margin-top: -1rem;
    margin: 0 auto;
  }

  &__img {
    width: 100%;
    flex: 1 1 50%;
    max-width: 35%;
    height: auto;
    margin-bottom: auto;
  }

  &__content {
    padding-left: 1rem;
  }

  &__title {
    font-size: 1rem;
    color: white;
    font-family: var(--ff-primary);
    font-weight: 400;
  }

  &__link {
    justify-content: center;
    padding: 0.5rem 1rem;
    border: none;
    background: linear-gradient(180deg, #33BB95 0%, #20AB8A 51.04%, #09977C 51.05%);
    color: white;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.8rem;
    text-shadow: 1px 1px black;
    text-decoration: none;
  }
}

.banner-donate--homepage{
  @include m_query(1500px) {
      display: none;
  }
}
