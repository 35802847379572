.breadcrumb-page {
  // margin-top: 2rem;

  ul {
    padding: 0;
    display: flex;
    list-style: none;

    @include m_query($boo-sm) {
      flex-wrap: wrap;
    }
  }

  li + li {
    position: relative;
    margin-left: 1.25rem;

    &::before {
      --size: 10px;
      content: '';
      position: absolute;
      top: 13px;
      left: -7px;
      width: var(--size);
      height: var(--size);
      transform: translate(-50%, -50%);
      background-image: url("data:image/svg+xml,%3Csvg width='5' height='10' viewBox='0 0 5 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 5L3.97232e-08 10L4.76837e-07 -2.18557e-07L5 5Z' fill='%23848484'/%3E%3C/svg%3E%0A");
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  li {
  }

  a {
    font-size: 0.813rem;
    font-weight: 700;
    color: var(--c-accent);
    text-decoration: none;
    transition: var(--g-trans) filter;
    white-space: nowrap;

    @include m_query($boo-sm) {
      font-size: .875rem;
    }

    @include hover {
      filter: brightness(.8);
    }
  }

  .active {
    a {
      color: var(--c-bk-3);
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      white-space: normal;
    }
  }
}
