.card-special {
  background: #F3F3F3;
  height: 100%;

  .is-manipulated {
    --bg: var(--c-accent-1);
    --text-color-meta: black;
    --icon-is-news: var(--icon-is-manipulated);

    &.card-special--dark {
      .card-special__subtitle {
        color: white;
      }
    }
  }

  .is-fake {
    --bg: var(--c-accent-2);
    --icon-is-news: var(--icon-is-fake);
  }

  .is-explainer {
    --bg: var(--c-accent-3);
    --icon-is-news: var(--icon-is-explainer);
  }

  &__link {
    display: block;
    height: 100%;
    text-decoration: none;
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
    background: transparent;
    transition: var(--g-trans) background;

    @include hover {
      background: var(--bg, black);
      color: white;

      .card-special__title {
        color: var(--text-color-meta, white);
      }

      .card-special__subtitle {
        background: white;
        color: var(--bg);
      }

      .card-special__img {
        transform: scale(1.1);
      }

      &.is-manipulated {
        .card-special__subtitle {
          color: black;
        }

        .card-special__meta {
          color: black;
        }
      }

      &.card-special--dark {

        .card-special__icon {
          transform: scale(1.2);
        }
      }
    }

  }

  &__img-wrp {
    position: relative;
    overflow: hidden;
    height: 240px;
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    inset: 0;
    transform: scale(1);
    transition: var(--g-trans) transform;
  }

  &__icon {
    --size: 5rem;
    background-image: var(--icon-is-news, var(--icon-is-fake));
    position: absolute;
    left: 1rem;
    bottom: 1rem;
    z-index: 1;
  }

  &__content-wrp {
    padding: 1rem 1.5rem;
    display: flex;
    flex-direction: column;

    > * + * {
      margin-top: 0.5rem;
    }
  }

  &__subtitle {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
    padding: 0.3rem 1.5rem;
    background: var(--bg, black);
    color: var(--text-color-meta, white);
    max-width: fit-content;
    transition: var(--g-trans) background;

    a {
      color: inherit;
      text-decoration: none;
    }
  }

  &__title {
    margin-bottom: 1.5rem;
    font-family: var(--ff-tertiary);
    font-size: var(--fs-3);
  }

  &__meta {
    margin-top: auto;
    font-size: .75rem;
    transition: var(--g-trans) background;
    text-transform: capitalize;

    > * + * {
      margin-left: 1rem;
      position: relative;

      &::before {
        --size: 100%;
        content: '';
        position: absolute;
        top: 50%;
        left: -.5rem;
        width: 1px;
        height: var(--size);
        transform: translateY(-50%);
        background: black;
      }
    }
  }

  &__section {
  }

  &__date {
  }

  /*
  ——— Variations
  */

  &--dark {
    background: black;
    color: white;

    .card-special {
      &__subtitle {
        background: transparent;
        border-top: 1px solid var(--bg);
        border-bottom: 1px solid var(--bg);

        a {
          color: inherit;
          text-decoration: none;
        }
      }

      &__icon {
        transform-origin: left bottom;
      }
    }
  }

}
